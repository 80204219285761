import * as api from "@startapp/laira-user-ssr-api";
import strings from "resources/Strings";
import Routes from "resources/Routes";
import { useUserAuth } from "../contexts/UserAuthContext";

const stringsCategory = strings.project.categories;

export interface Link {
	path: string;
	name: string;
}

export interface LinkContents {
	title: string;
	linkArray: Link[];
}

const links = (): LinkContents[] => {
	return [
		{
			title: strings.general.followUs,
			linkArray: [
				{
					name: strings.general.instagram,
					path: "https://www.instagram.com/necessial/",
				},
				{
					name: strings.general.facebook,
					path: "https://www.facebook.com/necessial",
				},
			],
		},
		{
			title: strings.general.categories,
			linkArray: [
				{
					name: stringsCategory[api.Category.clothes],
					path: "/search/clothes/?page=0",
				},
				{
					name: stringsCategory[api.Category.accessories],
					path: "/search/accessories/?page=0",
				},
				{
					name: stringsCategory[api.Category.toys],
					path: "/search/toys/?page=0",
				},
				{
					name: stringsCategory[api.Category.educational],
					path: "/search/educational/?page=0",
				},
				{
					name: stringsCategory[api.Category.health],
					path: "/search/health/?page=0",
				},
				{
					name: stringsCategory[api.Category.customized],
					path: "/search/customized/?page=0",
				},
				{
					name: stringsCategory[api.Category.party],
					path: "/search/party/?page=0",
				},
				{
					name: stringsCategory[api.Category.sport],
					path: "/search/sport/?page=0",
				},
			].sort((a, b) => {
				if (a.name > b.name) {
					return 1;
				}
				if (a.name < b.name) {
					return -1;
				}
				return 0;
			}),
		},
	];
};

export const changedLinks = (): LinkContents[] => {
	const userAuth = useUserAuth();

	let currentLinks = links();
	let hasStore = false;

	if (userAuth.user && userAuth.user.store) {
		hasStore = true;
	}

	currentLinks = [
		{
			title: strings.general.utilities,
			linkArray: [
				{
					name: strings.general.profile,
					path: Routes.profile().href,
				},
				{
					name: hasStore ? strings.general.mySales : strings.general.beASeller,
					path: hasStore ? Routes.orderHistoryStore().href : Routes.turnIntoStore().href,
				},
				{
					name: strings.general.help,
					path: Routes.help().href,
				},
				{
					name: strings.general.contact,
					path: Routes.contact().href,
				},
				{ name: strings.general.about, path: Routes.about().href },
				{ name: strings.general.userTerms, path: Routes.termsOfUse().href },
				{ name: strings.general.privacyPolicy, path: Routes.privacyPolicy().href },
			],
		},
		...currentLinks.filter(
			(link) => link.title !== strings.general.utilities,
		),
	];
	return currentLinks;
};

export const mobileLinks = (): LinkContents[] => [
	{
		title: strings.general.utilities,
		linkArray: [
			{ name: strings.general.about, path: Routes.about().href },
			{
				name: strings.general.instagram,
				path: "https://www.instagram.com/necessial/",
			},
			{
				name: strings.general.help,
				path: Routes.help().href,
			},
			{
				name: strings.general.contact,
				path: Routes.contact().href,
			},
			{ name: strings.general.userTerms, path: Routes.termsOfUse().href },
		],
	},
];

export const sideBarLinks = (): Link[] => {
	const sideLinks: Link[] = [];

	changedLinks()
		.filter(
			(linkTypes) =>
				linkTypes.title !== strings.general.categories &&
				linkTypes.title !== strings.general.followUs,
		)
		.forEach((linkContents) =>
			linkContents.linkArray.map((link) => sideLinks.push(link)),
		);
	return sideLinks;
};
export default links;
