import React from "react";
import {
	Box,
	Flex,
	Image,
	Text,
	SimpleGrid,
} from "@chakra-ui/react";
import Strings from "resources/Strings";
import { changedLinks, mobileLinks } from "resources/navigationLinks";
import { LinkList } from "./LinkList";

export const Footer: React.FC = () => {

	return (
		<>
			<Box
				display="flex"
				justifyContent={{ base: "flex-start", lg: "space-between" }}
				alignItems={{ base: "center", lg: "flex-start" }}
				width="100%"
				bgColor={"gray.100"}
				padding={{ sm: 6, md: 28 }}
				paddingBottom={28}
				marginBottom={{ base: 16, lg: 0 }}
				marginTop={50}
				flexDirection={{ base: "column", md: "row" }}
			>
				<Flex
					justifyContent="center"
					align={{ base: "center", lg: "flex-start" }}
					direction="column"
					maxW={{ base: "100%", lg: "35%" }}
					mr={{ base: "0", md: "5" }}
					mt={{ base: 5 }}
				>
					<Image marginBottom={5} alt="Logo" src={"/logo.svg"} />
					<Text
						textAlign={{ base: "center", lg: "start" }}
						display={{ base: "none", lg: "block" }}
					>
						{Strings.components.footer.address.copyright}
					</Text>
					<Text
						textAlign={{ base: "center", lg: "start" }}
						display={{ base: "Block", lg: "none" }}
						marginBottom={3}
					>
						{Strings.components.footer.address.copyright}
					</Text>
				</Flex>

				<SimpleGrid
					columns={{ lg: 3, md: 3, sm: 1 }}
					spacingX={35}
					spacingY={1}
					d={{ base: "none", md: "grid" }}
					w={{ base: "100%", md: "auto" }}
				>
					{
						changedLinks().map((listColumn, index) => (
							<LinkList
								key={`${listColumn.title}#${index}%${listColumn.title}`}
								linkArray={listColumn.linkArray}
								title={listColumn.title}
							/>
						))
					}
					<Text
						fontSize="sm"
						textAlign="center"
						position="absolute"
						bottom="0px"
						left="50%"
						transform={"translate(-50%, -50%)"}
						py={4}
					>
						{Strings.company.address}
					</Text>
				</SimpleGrid>
				<SimpleGrid
					columns={1}
					spacingX={35}
					spacingY={1}
					d={{ base: "grid", md: "none" }}
					w="100%"
				>
					{
						mobileLinks().map((listColumn, index) => (
							<LinkList
								key={`${listColumn.title}#${index}%${listColumn.title}`}
								linkArray={listColumn.linkArray}
								title={listColumn.title}
							/>
						))
					}
					<Flex pt={12} px={3}>
						<Text fontSize="sm" textAlign="center">
							{Strings.company.address}
						</Text>
					</Flex>
				</SimpleGrid>
			</Box>
		</>
	);
};
