import React from "react";
import { Flex, Text, Link, useMediaQuery, Divider, Box, Center } from "@chakra-ui/react";

import { LinkContents } from "resources/navigationLinks";

interface Props extends LinkContents { }

export const LinkList: React.FC<Props> = ({ linkArray, title }) => {
	const [isThen600] = useMediaQuery("(max-width: 800px)");

	return (
		<Flex flexDir="column">
			{!isThen600 && (
				<Text
					color="#4A5568"
					fontWeight="500"
					marginBottom={isThen600 ? 0 : 3}
					textAlign="left"
					fontSize={18}
				>
					{title}
				</Text>
			)}
			<Flex
				flexDir={{ sm: "row", md: "column" }}
				flexWrap="wrap"
				justifyContent="center"
			>
				<Divider display={{ base: "block", md: "none" }} borderBottomColor="#4A5568" />
				{linkArray.map((link, index) => (
					<Box
						color="#4A5568"
						fontWeight="350"
						textAlign={{ base: "center", lg: "start" }}
						w="100%"
						my={{base: 0, md: 2}}
						key={`${link.name}#${index}%${link.path}`}
					>
						<Center display={{ base: "flex", sm: "block" }}>
							<Link
								my={3}
								href={link.path}
							>
								{link.name}
							</Link>
						</Center>
						<Divider display={{ base: "block", md: "none" }} borderBottomColor="#4A5568" />
					</Box>
				))}
			</Flex>
		</Flex>
	);
};
