import React from "react";

// MARK: Component
import { Image } from "@chakra-ui/react";

interface IProps {
	src: string;
	right?: boolean;
}

export const ArrowComponent: React.FC<IProps> = (props) => {
	const { right, src } = props;

	return (
		<Image
			src={src}
			width="10px"
			cursor="pointer"
			position="absolute"
			zIndex="99"
			backgroundColor={"gray.200"}
			opacity={0.30}
			height={"40px"}
			top={-2}
			right={right && 0}
		/>
	);
};
