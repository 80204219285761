import React from "react";
import { Box } from "@chakra-ui/react";

export const PageContainer: React.FC = (props) => {
	return (
		<Box width="100%" height="100%">
			{props.children}
		</Box>
	);
};
