import React from "react";

// MARK: Styles
import { Box, Text, useBreakpointValue } from "@chakra-ui/react";

// MARK: Category Items
import { categories } from "./categories";

// MARK: Api
import * as api from "@startapp/laira-user-ssr-api";

// MARK: Lib
import ScrollMenu from "react-horizontal-scrolling-menu";

// MARK: componente
import { Link } from "../Link";
import { ArrowComponent } from "./ArrowComponent";
interface IProps {
	onPress?: () => void;
}

export const CategoriesBar: React.FC<IProps> = (props) => {
	const breakPoint = useBreakpointValue({ base: "mobile", md: "desktop" });
	const { onPress } = props;
	const listProduct = () => {
		return categories.map((category) => (
			<Link
				key={category + "_category"}
				href={`/search/${category}/?page=0`}
				textDecoration="none"
				_hover={{ textDecoration: "none" }}
			>
				<Text
					color="gray.600"
					textAlign="center"
					cursor="pointer"
					textTransform="uppercase"
					onClick={onPress}
					mr={{ base: 3, lg: 8 }}
					ml={{ base: 3, lg: 8 }}
					key={category}
					fontSize={{ base: "0.85rem", lg: "1rem" }}
				>
					{api.translateCategory(category)}
				</Text>
			</Link>
		));
	};

	return (
		<Box
			p={2}
			paddingX={0}
			w="100%"
			border="solid 1px"
			borderColor="gray.200"
			backgroundColor="gray.100"
		>
			<ScrollMenu
				data={listProduct()}
				alignCenter={true}
				disableTabindex={true}
				alignOnResize={true}
				menuStyle={{ position: "relative" }}
				wheel={false}
				arrowRight={
					breakPoint === "mobile" && (
						<ArrowComponent src="/arrowRightIcon.svg" right />
					)
				}
				arrowLeft={
					breakPoint === "mobile" && (
						<ArrowComponent src="/arrowLeftIcon.svg" />
					)
				}
			/>
		</Box>
	);
};
